.upload_body{
    position: absolute;
    width: 80px;
    height: 80px;
    background-color: #fff;
  }
  .upload_tittle{
    position: absolute;
    top: 5px;
    left: 3px;
    color: #fff;
    font-size: 12px;
    z-index: 6;
  }
    
  .upload_delImg{
    width: 18px;
    height: 18px;
    position: relative;
    display: none;
  }
    .upload_img {
      display: inline-block;
      position: relative;
      border: 1px solid #f1f4f7;
      float: left;
      width: 80px;
      height: 80px;
      border-left: none;
      margin-bottom: 3px;
    }
   .upload_img:hover .upload_delImg{
      display: block;
   }
    .upload_triangle{
      width: 50px;
      height: 50px;
      position: absolute;
      top:0;
      left: 0;
      z-index: 5;
    }
    .upload_delImg_wrapper{
      width: 18px;
      height: 18px;
      position: absolute;
      top:0;
      right: 0;
      z-index: 5;
      cursor: pointer;
      background-color: #fff;
    }
    
    .upload_addZoo{
      position: relative;
      cursor: pointer;
      width: 80px;
      height: 80px;
      background-color: #f2f2f2;
      color: #c2cad8;
      border-radius: 5px;
      margin: auto;
      top:9px;
      text-align: center;
      font-size: 32px;
      line-height: 80px;
    }
    
    .upload_img_warpper{
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .upload_body_center{
      position: absolute;
      width: 95px;
      height: 95px;
      left:calc(50% - 47.5px);
      top:calc(50% - 47.5px);
    }
  
.Login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width:100%;
    background: rgb(204,204,204);
}
.systemBox{
    display: flex;
    width:352px;
    height:347px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
}
.inputBox{
    width:80%;
    text-align: center;
}
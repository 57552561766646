.tableBtn{
    color:#3E92FA;
    cursor: pointer;
  }
.tableComponent .ant-table {
    min-height: 352px !important;
}
.tableComponent .ant-empty-normal {
    margin: 140px 0 !important;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    text-align: center;
    padding: 8px 8px;
    /* word-wrap: break-word;
    word-break: break-all; */
}
.mainHeader{
    background: #fff;
    color:#000;
    padding:10px 10px;
}
.mainHeader ul{
    list-style:none;
    margin-left: 15px;
}
.mainHeader li{
    float:left;
    font-size: 14px;
    margin-right:25px;
    color:#000;
    cursor: pointer;
}
.menuBtn{
    cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,ol,li,p{
  margin:0;padding:0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/  
::-webkit-scrollbar  
{  
    width: 6px;  
    height: 6px;  
    background-color: #F5F5F5;  
}  
  
/*定义滚动条轨道 内阴影+圆角*/  
::-webkit-scrollbar-track  
{  
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  
    border-radius: 10px;  
    background-color: #FFF;  
}  
  
/*定义滑块 内阴影+圆角*/  
::-webkit-scrollbar-thumb  
{  
    border-radius: 10px;  
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);  
    background-color: #AAA;  
} 
.ant-upload-list{
  display: none;
}




.Login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width:100%;
    background: rgb(204,204,204);
}
.systemBox{
    display: flex;
    width:352px;
    height:347px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
}
.inputBox{
    width:80%;
    text-align: center;
}
.mainHeader{
    background: #fff;
    color:#000;
    padding:10px 10px;
}
.mainHeader ul{
    list-style:none;
    margin-left: 15px;
}
.mainHeader li{
    float:left;
    font-size: 14px;
    margin-right:25px;
    color:#000;
    cursor: pointer;
}
.menuBtn{
    cursor: pointer;
}

.slider .ant-menu{
    font-weight: 800;
    font-size: 14px;
}
.tableBtn{
    color:#3E92FA;
    cursor: pointer;
  }
.tableComponent .ant-table {
    min-height: 352px !important;
}
.tableComponent .ant-empty-normal {
    margin: 140px 0 !important;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    text-align: center;
    padding: 8px 8px;
    /* word-wrap: break-word;
    word-break: break-all; */
}
.titleComponent{
    padding:0  25px;
    width:100%;
}
.titleSpan{
    font-size: 16px;
    font-weight: 600;
    border-left: 3px solid #3E92FA;
    padding-left: 5px;
}
.userAccountManage{
    background: #fff;
}
.upload_body{
    position: absolute;
    width: 80px;
    height: 80px;
    background-color: #fff;
  }
  .upload_tittle{
    position: absolute;
    top: 5px;
    left: 3px;
    color: #fff;
    font-size: 12px;
    z-index: 6;
  }
    
  .upload_delImg{
    width: 18px;
    height: 18px;
    position: relative;
    display: none;
  }
    .upload_img {
      display: inline-block;
      position: relative;
      border: 1px solid #f1f4f7;
      float: left;
      width: 80px;
      height: 80px;
      border-left: none;
      margin-bottom: 3px;
    }
   .upload_img:hover .upload_delImg{
      display: block;
   }
    .upload_triangle{
      width: 50px;
      height: 50px;
      position: absolute;
      top:0;
      left: 0;
      z-index: 5;
    }
    .upload_delImg_wrapper{
      width: 18px;
      height: 18px;
      position: absolute;
      top:0;
      right: 0;
      z-index: 5;
      cursor: pointer;
      background-color: #fff;
    }
    
    .upload_addZoo{
      position: relative;
      cursor: pointer;
      width: 80px;
      height: 80px;
      background-color: #f2f2f2;
      color: #c2cad8;
      border-radius: 5px;
      margin: auto;
      top:9px;
      text-align: center;
      font-size: 32px;
      line-height: 80px;
    }
    
    .upload_img_warpper{
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .upload_body_center{
      position: absolute;
      width: 95px;
      height: 95px;
      left:calc(50% - 47.5px);
      top:calc(50% - 47.5px);
    }
  
.viewManage{
    background: #fff;
}
.superadminManage{
    background: #fff;
}
.setPermission{
    background: #fff;
}
.helpManage{
    background: #fff;
}
.systemSafe{
    background: #fff;
    height:400px;
}
.tagManage{
    background: #fff;
}
.guideManage{
    background: #fff;
}
.regionManage{
    background: #fff;
}
